<template>
  <div class="container">
    <van-list v-model:loading="loading" :finished="finished"
      v-show="list != null && list.length > 0" class="box"
      finished-text="我也是有底线的呦~" @load="getRecord">
      <div class="box-row" v-for="item in list" :key="item.id">
        <div class="box-row-top">
          <div class="brt-status">{{item.status=='10'?'结算中':'已结算'}}</div>
          <div class="brt-money">￥{{$utils.setMoneyFormat(item.settlementMoney)}}
          </div>
        </div>
        <div class="box-row-btm">
          <div class="brb-time">{{item.settlementData}}</div>
        </div>
      </div>
    </van-list>
    <van-empty class="empty" description="暂无提现记录"
      v-show="list != null && list.length == 0" />
  </div>
</template>

<script>
import { getwithdrawaLog } from '@/api/profit.js'
export default {
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      list: [],
      page: 1
    }
  },
  mounted() {
    this.getRecord()
  },
  methods: {
    async getRecord() {
      let res = []
      // 达人收益
      res = await getwithdrawaLog({ page: this.page, limit: 30 })
      this.list.push(...res.list)
      this.loading = false
      if (this.list.length >= res.count) {
        this.finished = true
      } else {
        this.finished = false
        this.page += 1
      }
    }
  }
}
</script>
<style scoped lang="less">
.container {
  background: white;
  min-height: 100vh;
}
.box {
  padding: 0px 30px;
  .box-row {
    padding: 30px;
    border-bottom: 1px solid #f1f2f6;
    .box-row-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      .brt-status {
        font-size: 30px;
        color: #fe774c;
      }
      .brt-money {
        font-size: 26px;
        color: #f04040;
      }
    }
    .box-row-btm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .brb-time {
        color: #999999;
        font-size: 28px;
      }
      .brb-time {
        font-size: 26px;
        color: #999999;
      }
    }
  }
}
.empty {
  height: 100%;
  /deep/ .van-empty__image {
    width: 200px;
    height: 200px;
  }
}
</style>
